import React from "react";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import UserAvatar from "components/Shared/UserAvatar";
import { Link } from "react-router-dom";
import { getInitialsFromName } from "helpers/utilHelper";
import { formatTimestamp, getMessageDateFormat } from "helpers/dateHelper";
import { Col } from "reactstrap";
import { route, routes } from "helpers/routeHelper";
import UserHeader from "./UserHeader";
import Message from "model/message";

const Sidebar = ({ id, channelId, messages, order }) => {

  const dealerArray = Message.getDealerChannelMap();
  const supportArray = Message.getTechChannelMap();

  const filtredDealerArray = order.isNotaryRequired
    ? dealerArray
    : { [Message.CHANNEL_DEALER_CUSTOMER]: dealerArray[Message.CHANNEL_DEALER_CUSTOMER] };

  return (
    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
      <div className="me-lg-4">
        <div className="py-2 border-bottom">
          <UserHeader />
        </div>

        <div className="chat-leftsidebar-nav">
          <div className="py-4">
            <h5 className="font-size-12 mb-3"><strong>Dealers Conversations - for all your processing order</strong></h5>
            <ul className="list-unstyled chat-list" id="recent-list">
              <PerfectScrollbar>
                {Object.keys(filtredDealerArray).map(chId => {
                  const channel = filtredDealerArray[chId];
                  const channelMessages = messages[chId];
                  const lastMessage = channelMessages?.length ? channelMessages[channelMessages.length - 1] : ''

                  return (
                    <li
                      key={chId}
                      className={
                        channelId == chId
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={route(routes.view_order_messages, [id, chId])}>
                        <div className="d-flex">
                          <div className="align-self-center me-3">
                            <UserAvatar id={+chId} initials={getInitialsFromName(channel)} size="sm" />
                          </div>

                          <div className="flex-grow-1 overflow-hidden my-auto">
                            <h5 className="text-truncate font-size-14 mb-1">
                              {channel}
                            </h5>
                            {lastMessage && <div className="text-truncate">
                              {lastMessage.contentType === Message.CONTENT_TYPE_TEXT && lastMessage.content}
                              {lastMessage.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex">
                                <i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span>
                              </div>}
                            </div>}
                          </div>
                          {lastMessage && <div className="font-size-11">
                            {formatTimestamp(lastMessage.createdTs, getMessageDateFormat(lastMessage.createdTs))}
                          </div>}
                        </div>
                      </Link>
                    </li>
                  );
                })}
                <h5 className="font-size-12 mb-3 mt-4"><strong>Support Conversations - for all your technical issues </strong></h5>
                {Object.keys(supportArray).map(chId => {
                  const channel = supportArray[chId];
                  const channelMessages = messages[chId];
                  const lastMessage = channelMessages?.length ? channelMessages[channelMessages.length - 1] : ''

                  return (
                    <li
                      key={chId}
                      className={
                        channelId == chId
                          ? "active"
                          : ""
                      }
                    >
                      <Link to={route(routes.view_order_messages, [id, chId])}>
                        <div className="d-flex">
                          <div className="align-self-center me-3">
                            <UserAvatar id={+chId} initials={getInitialsFromName(channel)} size="sm" />
                          </div>

                          <div className="flex-grow-1 overflow-hidden my-auto">
                            <h5 className="text-truncate font-size-14 mb-1">
                              {channel}
                            </h5>
                            {lastMessage && <div className="text-truncate">
                              {lastMessage.contentType === Message.CONTENT_TYPE_TEXT && lastMessage.content}
                              {lastMessage.contentType === Message.CONTENT_TYPE_IMAGE && <div className="d-flex">
                                <i className="mdi mdi-camera me-1" /> <span className="font-size-12">Image</span>
                              </div>}
                            </div>}
                          </div>
                          {lastMessage && <div className="font-size-11">
                            {formatTimestamp(lastMessage.createdTs, getMessageDateFormat(lastMessage.createdTs))}
                          </div>}
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </PerfectScrollbar>
            </ul>
          </div>
        </div>
      </div>
    </Col>
  )
}

Sidebar.propTypes = {
  id: PropTypes.number,
  channelId: PropTypes.number,
  messages: PropTypes.object,
  channels: PropTypes.object,
  order: PropTypes.object,
}

export default Sidebar;